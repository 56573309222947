import {Injectable} from "@angular/core";
import {WabelService} from "./wabel-service.abstract";
import {GraphQLService} from "./graphql-service";
import {LoadingService} from "./loading.service";

import {Company} from "../entities/company";
import {CompanyGraphQL} from "../graphql/company.graphql";
import {UtilsService} from "../../utils.service";
import {CompanyTypes} from "../company_types";
import {FileToUpload} from "../entities/fileToUpload";
import {CompanyFile} from "../entities/company_file";
import {Member} from "../entities/member";
import {map} from "rxjs/operators";
import {DummyFile} from "../entities/dummy_file";
import {EcoPackProjectGraphQL} from "../graphql/EcoPackProject.graphql";
import {ResultIterator} from "../entities/result_iterator";
import {EcoPackProjectOption} from "../entities/eco-pack-project-option";
import {CompanyPolicyOption} from "../entities/company_policy_option";
import {CompanyProfileSubmission} from "../entities/company_profile_submission";
import {EventOrganizer} from '../entities/event_organizer';
import gql from "graphql-tag";
import {TagTypeService} from "./tag_type.service";
import {CompanyVideo} from "../entities/company_video";
import {BusinessMappingPriority} from "../entities/business_mapping_priority";
import {SourcingRequest} from "../entities/sourcing_request";
import {SourcingNeed} from "../entities/sourcing_need";

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends WabelService {

    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService, protected utilsService: UtilsService) {
        super(graphQLService, loadingService);
    }

    companyProfileSubmissionsToArrayOfObjects(objects: any[]): CompanyProfileSubmission[] {
        return objects.map((companySubmission: any) => new CompanyProfileSubmission(companySubmission));
    }

    toArrayOfObjects(objects: any[]): Company[] {
        return objects.map((company: any) => new Company(company));
    }

    TagsOptiontoArrayOfObjects(objects: any[]): CompanyPolicyOption[] {
        return objects.map((company: any) => new CompanyPolicyOption(company));
    }

    toObject(object: any): Company {
        return new Company(object);
    }

    companyInfoPrivateMessages(idcompany: number) {
        return this.query(CompanyGraphQL.queries.companyInfoPrivateMessages, {idcompany: idcompany})
            .pipe(map((data) => data.data ? new Company(data.data.company) : null));
    }

    getSimpleListByFilter(limit: number = 100, offset: number = 0, sort: string = 'idcompany', order: string = 'ASC', search: string = '', idCompanyTypes: number[] = [],) {
        return this.query(CompanyGraphQL.queries.findCompaniesByName, {
            limit: limit,
            offset: offset,
            order: (sort && order ? sort + ' ' + order : null),
            search: search,
            idCompanyTypes: idCompanyTypes
        })
            .pipe(map((data) => data.data ? {
                count: data.data.findCompaniesByName.count,
                items: this.toArrayOfObjects(data.data.findCompaniesByName.items)
            } : null));
    }

    sendMailPremiumRequest(companyTarget: Company, sourcePage: string, membershipRequested: string = 'Essential', sourcingRequest: SourcingRequest, sourcingNeed: SourcingNeed) {
        return this.mutation(CompanyGraphQL.mutations.sendMailPremiumRequest, {
            idCompanyTarget: +companyTarget.idcompany,
            sourcePage: sourcePage,
            membershipRequested: membershipRequested,
            idSourcingNeed: sourcingNeed?.id,
            idSourcingRequest: sourcingRequest?.id
        }).pipe(map((data) => data.data.sendMailPremiumRequest));
    }

    createAdministrativeContact(idcompany: number, eventCode: string, firstName: string, lastName: string, email: string) {
        return this.mutation(CompanyGraphQL.mutations.createAdministrativeContact, {
            idcompany: idcompany, eventCode: eventCode, firstName: firstName, lastName: lastName, email: email
        }).pipe(map((data) => data.data && data.data.createAdministrativeContact ? new Member(data.data.createAdministrativeContact.administrativeContact) : null));
    }

    editAdministrativeContact(idcompany: number, eventCode: string, idmember: number) {
        return this.mutation(CompanyGraphQL.mutations.editAdministrativeContact, {
            idcompany: idcompany, eventCode: eventCode, idmember: idmember
        }).pipe(map((data) => data.data && data.data.editAdministrativeContact ? new Member(data.data.editAdministrativeContact.administrativeContact) : null));
    }

    getCompaniesByIds(idsCompany: number[]) {
        return this.query(CompanyGraphQL.queries.companiesByIds, {
            ids: idsCompany
        }).pipe(map((data) => data.data && data.data.companiesById ? this.toArrayOfObjects(data.data.companiesById) : []));
    }

    getCompanyById(idCompany: number) {
        return this.query(CompanyGraphQL.queries.companyById, {
            id: idCompany
        }).pipe(map((data) => data.data && data.data.companyById ? new Company(data.data.companyById) : null));
    }

    getInformationForProductRequestCompanyModal(urlname: string) {
        return this.query(CompanyGraphQL.queries.getInformationForProductRequestCompanyModal, {
            urlname: urlname
        }).pipe(map((data) => data.data ? new Company(data.data.companyByUrlname) : null))
    }

    getEditCompanyProfileAdmin(idcompany: number) {
        return this.query(CompanyGraphQL.queries.getEditProfileCompanyAdmin, {
            idcompany: idcompany
        }).pipe(map((data) => data.data ? new Company(data.data.company) : null));
    }

    getSummitRegistrations(summitCode: string) {
        return this.query(CompanyGraphQL.queries.getSummitRegistrations, {
            summitCode: summitCode
        }).pipe(map((data) => data.data && data.data.me ? new Company(data.data.me.company) : null));
    }

    getEventRegistrations(eventCode: string) {
        return this.query(CompanyGraphQL.queries.getEventRegistrations, {
                eventCode: eventCode
            },
            'network-only'
        ).pipe(map((data) => data.data && data.data.me ? new Company(data.data.me.company) : null));
    }

    getAllMembers() {
        return this.query(CompanyGraphQL.queries.getAllMembers, null, 'network-only').pipe(map((data) => {
            return data.data && data.data.me && data.data.me.company ? data.data.me.company.allMembers.map(member => new Member(member)) : null;
        }));
    }

    getCompanyProfilePage(urlname: string) {
        return this.query(CompanyGraphQL.queries.companyProfilePage, {urlname: urlname}, 'network-only')
            .pipe(map((data) => data.data && data.data.companyByUrlname ? new Company(data.data.companyByUrlname) : null));
    }

    getCompanyInformationRelatedToMe(urlname: string) {
        return this.query(CompanyGraphQL.queries.companyInformationRelatedToMe, {urlname: urlname}, 'network-only')
            .pipe(map((data) => data.data && data.data.companyByUrlname ? new Company(data.data.companyByUrlname) : null));
    }

    getSiblingsCompanies(urlname: string) {
        return this.query(CompanyGraphQL.queries.siblingsCompanies, {urlname: urlname}, 'network-only')
            .pipe(map((data) => data.data && data.data.companyByUrlname ? data.data.companyByUrlname.buyingOffices.map(c => new Company(c)) : []));
    }

    similarCompaniesForCompany(urlname: string) {
        return this.query(CompanyGraphQL.queries.similarCompanies, {urlname: urlname})
            .pipe(map((data) => data.data && data.data.companyByUrlname && data.data.companyByUrlname.similarCompanies ? data.data.companyByUrlname.similarCompanies.map(c => new Company(c)) : []));
    }

    relativeCompaniesInCountry(urlname: string) {
        return this.query(CompanyGraphQL.queries.relativeCompaniesInCountry, {urlname: urlname})
            .pipe(map((data) => data.data && data.data.companyByUrlname && data.data.companyByUrlname.relativeCompaniesInCountry ? data.data.companyByUrlname.relativeCompaniesInCountry.map(c => new Company(c)) : []));
    }


    getCompanyProfileForEvent(urlname: string, eventCode: string, fetchPolicy = "cache-and-network") {
        return this.query(CompanyGraphQL.queries.getCompanyProfileForEvent, {
            urlname: urlname,
            eventCode: eventCode
        }, fetchPolicy)
            .pipe(map((data) => data.data && data.data.companyByUrlname ? new Company(data.data.companyByUrlname) : null));
    }


    getCompanyProfileForCompanyDetails(urlname: string, fetchPolicy = "cache-and-network") {
        return this.query(CompanyGraphQL.queries.getCompanyProfileForCompanyDetailsModal, {
            urlname: urlname
        }, fetchPolicy)
            .pipe(map((data) => data.data && data.data.companyByUrlname ? new Company(data.data.companyByUrlname) : null));
    }

    getCompanyProfileForOpenCatalog(urlname: string, eventCode: string) {
        return this.query(CompanyGraphQL.queries.getCompanyProfileForOpenCatalog, {
            urlname: urlname,
            eventCode: eventCode
        })
            .pipe(map((data) => data.data && data.data.companyByUrlname ? new Company(data.data.companyByUrlname) : null));
    }

    checkFileUploading(company: Company) {
        let isStillUploading = false;
        if (company) {
            if (company.newCompanyLogo && company.newCompanyLogo.length) {
                for (let i = 0; i < company.newCompanyLogo.length; i++) {
                    if (company.newCompanyLogo[i].isUploading()) {
                        isStillUploading = true;
                        break;
                    }
                }
            }
            if (isStillUploading) {
                return true;
            }
            if (company.newCompanyFiles && company.newCompanyFiles.length) {
                for (let i = 0; i < company.newCompanyFiles.length; i++) {
                    if (company.newCompanyFiles[i].isUploading()) {
                        isStillUploading = true;
                        break;
                    }
                }
            }
            if (isStillUploading) {
                return true;
            }
            if (company.newCompanyImages && company.newCompanyImages.length) {
                for (let i = 0; i < company.newCompanyImages.length; i++) {
                    if (company.newCompanyImages[i].isUploading()) {
                        isStillUploading = true;
                        break;
                    }
                }
            }
        }
        return isStillUploading;
    }

    resetCompanyUploadElements(company: Company, companyCopy: Company) {
        companyCopy.currentCompanyLogo = [];
        companyCopy.newCompanyLogo = [];
        companyCopy.newCompanyFiles = [];
        companyCopy.newCompanyImages = [];
        if (company.logo) {
            let f = new DummyFile();
            f.fileName = 'Logo';
            f.fileUrl = company.logoUrl;
            companyCopy.currentCompanyLogo = [
                f
            ];
        }
        if (company.companyImages.length) {
            companyCopy.companyImages = [];
            for (let index in company.companyImages) {
                let image = new CompanyFile(company.companyImages[index]);
                companyCopy.companyImages.push(image);
            }
        }
        if (company.files.length) {
            companyCopy.files = [];
            for (let index in company.files) {
                let file = new CompanyFile(company.files[index]);
                companyCopy.files.push(file);
            }
        }
    }

    unsubscribeRequests(company: Company) {
        if (company) {
            if (company.newCompanyLogo && company.newCompanyLogo.length) {
                for (let i = 0; i < company.newCompanyLogo.length; i++) {
                    if (company.newCompanyLogo[i].requestUpload) {
                        company.newCompanyLogo[i].requestUpload.unsubscribe();
                    }
                }
            }
            if (company.newCompanyFiles && company.newCompanyFiles.length) {
                for (let i = 0; i < company.newCompanyFiles.length; i++) {
                    if (company.newCompanyFiles[i].requestUpload) {
                        company.newCompanyFiles[i].requestUpload.unsubscribe();
                    }
                }
            }
            if (company.newCompanyImages && company.newCompanyImages.length) {
                for (let i = 0; i < company.newCompanyImages.length; i++) {
                    if (company.newCompanyImages[i].requestUpload) {
                        company.newCompanyImages[i].requestUpload.unsubscribe();
                    }
                }
            }
        }
    }


    updateCompanyEcoPolicy(description, ecofriendlyoptions) {
        return this.mutation(CompanyGraphQL.mutations.updateCompanyEcoPolicy, {
            description: description,
            ecofriendlyoptions: ecofriendlyoptions
        }).pipe(map((data) => data.data && data.data.updateCompanyEcoPolicy ? new Company(data.data.updateCompanyEcoPolicy) : null));
    }

    getMyCompanyProfileSubmissionsWithBuyerCompany(buyerCompanyId: number) {
        return this.query(CompanyGraphQL.queries.getMyCompanyProfileSubmissionsWithBuyerCompany, {buyerCompanyId}, 'network-only')
            .pipe(map((data) => data.data && data.data.myCompanyProfileSubmissionsWithBuyerCompany ? this.companyProfileSubmissionsToArrayOfObjects(data.data.myCompanyProfileSubmissionsWithBuyerCompany.items) : null));
    }

    getMyCompanyForAnalyticsDashboard(dateRange: string) {
        return this.query(CompanyGraphQL.queries.getMyCompanyAnalyticsDashboard, {
            dateRange: dateRange
        }).pipe(map((data) => data.data && data.data.me && data.data.me.company ? new Company(data.data.me.company) : null));
    }

    updateCompanyInformation(company: Company) {
        return this.mutation(CompanyGraphQL.mutations.updateCompanyInformation, {
            idcompany: +company.idcompany,
            country_name: company.country ?? null,
            region_name: company.regionName ?? null,
            city_name: company.cityName ?? null,
            postal_code: company.postalCode ?? null,
            formatted_address: company.formattedAddress,
            website: company.website,
            phone: company.phone,
            description: company.description,
            valueProposition: company.valueProposition,
            logo: company.logo,
            stockAvailability: company.stockAvailability
        })
            .pipe(map((data) => data.data ? new Company(data.data.updateCompanyInformation) : null));
    }

    updateCompanyFactAndFigures(form: any) {
        return this.mutation(CompanyGraphQL.mutations.updateCompanyFactAndFigures, form)
            .pipe(map((data) => data.data ? new Company(data.data.updateCompanyFactAndFigures) : null));
    }

    updateCompanyDistribution(form: any) {
        return this.mutation(CompanyGraphQL.mutations.updateCompanyDistribution, form)
            .pipe(map((data) => data.data ? new Company(data.data.updateCompanyDistribution) : null));
    }

    saveMemberCompletedDataConfirmation(form: any, typeOfData: string) {
        return this.mutation(CompanyGraphQL.mutations.saveMemberCompletedDataConfirmation, {
            idcompany: form.idcompany,
            userConfirmation: form.userConfirmation.length ? true : false,
            typeOfData: typeOfData
        })
            .pipe(map((data) => data.data ? new Company(data.data.saveMemberCompletedDataConfirmation) : null));
    }

    updateCompanyMarketing(formValue) {
        return this.mutation(CompanyGraphQL.mutations.updateCompanyMarketing, formValue)
            .pipe(map((data) => data.data && data.data.updateCompanyMarketing ? new Company(data.data.updateCompanyMarketing) : null));
    }

    updateCompanyEcoPackPolicy(form) {
        return this.mutation(CompanyGraphQL.mutations.updateCompanyEcoPackPolicy, form)
            .pipe(map(data => new Company(data.data.updateCompanyEcoPackPolicy)));
    }

    updateCompanyProduction(company: Company, moq: number[], countries: number[], categories: number[]) {
        return this.mutation(CompanyGraphQL.mutations.updateCompanyProduction, {
            idcompany: +company.idcompany,
            categories: categories,
            countries: countries.filter(country => typeof country !== 'undefined'),
            moq: moq,
            productionCapacity: company.productionCapacity,
            privateLabelParts: company.privateLabelParts
        })
            .pipe(map((data) => data.data ? new Company(data.data.updateCompanyProduction) : null));
    }

    updateCompanyCertifications(form: any) {
        return this.mutation(CompanyGraphQL.mutations.updateCompanyCertifications, {
            idcompany: form.idcompany,
            certifications: form.certificationsTags ? form.certificationsTags.filter(x => !isNaN(+x)) : [],
            customCertifications: form.certificationsTags ? form.certificationsTags.filter(x => isNaN(+x)) : [],
            certificationsDocuments: form.certificationsDocuments,
        }).pipe(map((data) => data.data ? new Company(data.data.updateCompanyCertifications) : null));
    }

    updateCompanyClients(form: any) {
        return this.mutation(CompanyGraphQL.mutations.updateCompanyClients, {
            idcompany: form.idcompany,
            clients: form.clients.map(c => {
                return {
                    idcompany: form.idcompany,
                    idclient: isNaN(c) ? null : c,
                    label: isNaN(c) ? c : null,
                };
            }),
            userConfirmation: !!form.userConfirmation.length
        }).pipe(map((data) => new Company(data.data.updateCompanyClients)));
    }

    getProductionStatusForMyCompany() {
        return this.query(CompanyGraphQL.queries.getMyCompanyProductionStatus, {}, 'network-only')
            .pipe(map((data) => data.data && data.data.me && data.data.me.company ? new Company(data.data.me.company) : null));
    }

    getInformationForVideoMeetingRequestButton(idCompany: number) {
        return this.query(CompanyGraphQL.queries.getInformationForVideoMeetingRequestButton, {
            idcompany: idCompany
        }).pipe(map((data) => data.data ? new Company(data.data.company) : null))
    }

    getInformationForVideoMeetingRequestModal(idCompany: number) {
        return this.query(CompanyGraphQL.queries.getInformationForVideoMeetingRequestModal, {
            idcompany: idCompany
        }).pipe(map((data) => data.data ? new Company(data.data.company) : null))
    }

    getInformationForVideoMeetingInviteColleaguesModal(idCompany: number) {
        return this.query(CompanyGraphQL.queries.getInformationForVideoMeetingInviteColleaguesModal, {
            idcompany: idCompany
        }).pipe(map((data) => data.data ? new Company(data.data.company) : null))
    }

    getCompanyProfileDetails(urlname: string, businessMappingPriority: BusinessMappingPriority = null) {
        return this.query(CompanyGraphQL.queries.companyProfileDetails, {
            urlname: urlname,
            businessMappingPriority: {id: businessMappingPriority.id}
        }, 'network-only')
            .pipe(map((data) => data.data && data.data.companyByUrlname ? new Company(data.data.companyByUrlname) : null));
    }

    getCompanyGeographicalBreakdown(urlname: string) {
        return this.query(CompanyGraphQL.queries.companyGeographicalBreakdown, {urlname: urlname}, 'network-only')
            .pipe(map((data) => data.data && data.data.companyByUrlname ? new Company(data.data.companyByUrlname) : null));
    }

    getCompanyGeographicalProducts(urlname: string, businessMappingPriority: BusinessMappingPriority = null) {
        return this.query(CompanyGraphQL.queries.companyProducts, {
            urlname: urlname,
            businessMappingPriority: businessMappingPriority?.id ? {id: businessMappingPriority.id} : null
        }, 'network-only')
            .pipe(map((data) => data.data && data.data.companyByUrlname ? new Company(data.data.companyByUrlname) : null));
    }

    getCompanyCountriesOfActivity(urlname: string, businessMappingPriority: BusinessMappingPriority = null) {
        return this.query(CompanyGraphQL.queries.companyCountriesOfActivity, {
            urlname: urlname,
            businessMappingPriority: {id: businessMappingPriority.id}
        }, 'network-only')
            .pipe(map((data) => data.data && data.data.companyByUrlname ? new Company(data.data.companyByUrlname) : null));
    }

    getRemainingProductRequestNumberForMyCompany() {
        return this.query(CompanyGraphQL.queries.getRemainingProductRequestNumberForMyCompany)
            .pipe(map((data) => data.data && data.data.me && data.data.me.company ? new Company(data.data.me.company) : null));
    }

    getMySourcingRelationships() {
        return this.query(CompanyGraphQL.queries.getMySourcingRelationships)
            .pipe(map((data) => data.data && data.data.mySourcingRelationships ? this.toArrayOfObjects(data.data.mySourcingRelationships.items) : []));
    }

    getActiveProductRequestOpportunities() {
        return this.query(CompanyGraphQL.queries.getActiveProductRequestOpportunities)
            .pipe(map((data) => +data.data.me.company.activeProductRequestOpportunities.count));
    }

    importCompanyMissingImages(ids: number[], excludeProducts: boolean = false) {
        return this.mutation(CompanyGraphQL.mutations.importCompanyMissingImages, {
            ids, excludeProducts
        }).pipe(map((data) => data.data && data.data.importCompanyMissingImages ? this.toArrayOfObjects(data.data.importCompanyMissingImages) : []));
    }
}
